<template>
<div class="main">
  <div class="main_right">
    <VxeTreeTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeTreeTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      gridOptions: {
        rowId: 'id',
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists()
            }
          }
        },
        // 列表字段配置
        columns: [{
            type: 'seq',
            width: 60
          },
          {
            field: 'name',
            title: '名称',
            minWidth: 160,
            treeNode: true,
            align: "left",
            editRender: {
              name: '$input',
              props: {
                maxlength: 24,
              }
            }
          },
          {
            field: 'code',
            title: '权限标识',
            align: "left",
            minWidth: 160,
            editRender: {
              name: '$input',
              props: {
                maxlength: 32,
              }
            }
          },
          {
            field: "type",
            title: "类型",
            minWidth: 80,
            editRender: {
              name: "$select",
              optionTypeCode: "menuType",
              props: {
                placeholder: "请选择企业类型",
              },
            },
          },
          {
            field: 'service',
            title: '接口地址',
            align: "left",
            minWidth: 200,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "/"
              } else {
                return cellValue
              }
            },
            editRender: {
              name: '$input',
            }
          },
          {
            field: 'linkType',
            title: '链接类型',
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "linkType",
            }
          },
          {
            field: 'linkAddress',
            title: '链接地址',
            align: "left",
            headerAlign: "center",
            minWidth: 240,
            editRender: {
              name: '$input',
              props: {}
            }
          },
          {
            field: 'icon',
            title: '图标',
            align: "left",
            minWidth: 150,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "/"
              } else {
                return cellValue
              }
            },
            editRender: {
              name: '$VxeSelectIcon'
            }
          },
          {
            field: 'orderCode',
            title: '排序',
            width: 80,
            editRender: {
              name: '$input',
              props: {
                maxlength: 3,
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            width: 100,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: '$VxeGridButtons',
              lists: [{
                  name: "addButton",
                  status: "primary",
                  params: {
                    property: "type",
                    value: ["2"],
                  },
                  events: {
                    click: (data) => {
                      this.$refs.xGrid.insertEvent();
                      let { formData } = this.$refs.xGrid;
                      formData["parentId"] = data.id;
                      formData["type"] = "3";
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  eventName: "queryEvent"
                },
                {
                  name: "delete",
                  status: "danger",
                  eventName: "removeEvent"
                }
              ]
            },
          },
        ],
        treeConfig: {
          children: 'children',
          // accordion: true,
          reserve: true,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {}
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: 'parentId',
            title: '上级栏目',
            span: 24,
            itemRender: {
              name: "$VxeSelectParent",
              service: "/sys/sysAppResource",
              props: {
                placeholder: "请选择上级栏目"
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            }
          },
          {
            field: 'name',
            title: '名称',
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入名称"
              }
            }
          },
          {
            field: 'type',
            title: '类型',
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "menuType",
              props: {
                placeholder: "请选择类型"
              }
            }
          },
          {
            field: 'code',
            title: '权限标识',
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 32,
                placeholder: "请输入权限标识"
              }
            }
          },
          {
            field: 'icon',
            title: '图标',
            span: 12,
            visibleMethod({ data, property }) {
              if (data.type == "1") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$VxeSelectIcon",
              props: {
                placeholder: "请选择图标"
              }
            }
          },
          {
            field: 'service',
            title: '接口地址',
            span: 24,
            visibleMethod({ data, property }) {
              if (data.type == "2") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入接口地址"
              }
            }
          },
          {
            field: 'linkType',
            title: '链接类型',
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "linkType",
              props: {
                placeholder: "请选择链接类型"
              }
            }
          },
          {
            field: 'linkAddress',
            title: '链接地址',
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入链接地址"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'orderCode',
            title: '排序',
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 0,
                max: 9999,
                maxlength: 3,
                placeholder: "请输入排序号"
              }
            }
          },
          {
            align: 'center',
            span: 24,
            itemRender: {
              name: '$buttons',
              children: [{
                  props: {
                    type: 'submit',
                    content: '提交',
                    status: 'primary'
                  }
                },
                {
                  props: {
                    type: 'reset',
                    content: '取消'
                  }
                }
              ]
            }
          },
        ],
        //表单校验规则
        rules: {
          name: [
            { required: true, message: '请输入名称' },
          ],
          code: [
            { required: true, message: '请输入权限标识' },
          ],
          type: [
            { required: true, message: '请选择类型' },
          ],
          linkType: [
            { required: true, message: '请选择链接类型' },
          ],
          linkAddress: [
            { required: true, message: '请输入链接地址' },
          ],
          orderCode: [
            { required: true, message: '请输入排序号' },
          ],
          enabled: [
            { required: true, message: '请输选择状态' },
          ],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          name: "",
          code: "",
          type: "2",
          linkType: "1",
          linkAddress: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "keyWords",
            title: "名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        searchProps: ['name', 'creditCode', 'code', 'shortName'],
        data: {
          keyWords: "",
        },
      },
    }
  },
}
</script>

<style scoped>

</style>
